<template>
  <div style="padding: 10px">
    <van-form @submit="onSubmit" :label-width="60">
      <van-field
        readonly
        clickable
        name="carNumber"
        :value="carNumber"
        label="车牌号"
        placeholder="点击输入车牌号"
        @click="showInputCar = true"
      />

      <div style="margin: 20px">
        <van-button
          round
          block
          type="info"
          native-type="onSubmit"
          :disabled="!carNumber"
        >
          确认
        </van-button>
      </div>
    </van-form>

    <!-- <van-button plain round style="width: 90%;" type="primary" @click="Continue">
            继续录入上一辆
        </van-button> -->

    <van-popup v-model="showInputCar" round position="bottom">
      <div style="padding: 20px">
        <vue-vehicle-plate-2
          v-if="keyboardDIY"
          @confirm="submit"
        ></vue-vehicle-plate-2>
        <vue-vehicle-plate
          v-if="!keyboardDIY"
          @confirm="submit"
        ></vue-vehicle-plate>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Form, Field, Popup, Button, Toast, Dialog } from "vant";

import VueVehiclePlate from "vue-vehicle-plate";
import VueVehiclePlate2 from "../components/vue-vehicle-plate";
import Vue from "vue";
Vue.use(VueVehiclePlate);
Vue.use(VueVehiclePlate2);

import moment from "moment";

export default {
  components: {
    vanForm: Form,
    vanField: Field,
    vanPopup: Popup,
    vanButton: Button,
  },
  data() {
    return {
      showInputCar: false,
      carNumber: null,

      list: [],
      keyboardDIY: false,
    };
  },
  mounted() {
    if (this.$store.getters.DATAUPLOAD) {
      this.list = this.$store.getters.DATAUPLOAD.bus_list;
    }
    if (this.$store.getters.USER.domain === "DOMAIN-9") {
      this.keyboardDIY = true;
    } else {
      this.keyboardDIY = false;
    }
  },
  methods: {
    Continue() {
      if (this.list.length > 0) {
        console.log(this.list);

        let car = this.list[this.list.length - 1];

        Dialog.confirm({
          title: "车牌号：" + car.plate_number,
          message: "确认继续录入这辆车吗？",
        })
          .then(() => {
            // on confirm
            this.$router.replace({
              name: "scan",
              params: {
                car_number: car.plate_number,
                registration_time: car.registration_time,
                effective_number_artificial: car.effective_number_artificial,
                invalid_number_artificial: car.invalid_number_artificial,
                code_number: car.code_number,
                sign_up_ids: car.sign_up_ids,
                users: car.users,
              },
            });
          })
          .catch(() => {
            // on cancel
          });
      } else {
        Toast.fail("没有本地数据");
      }
    },
    checkFormat(str) {
      return /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/.test(
        str
      );
    },
    onSubmit() {
      if (this.carNumber) {
        let now = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

        let car = {
          plate_number: this.carNumber,
          registration_time: now,
          effective_number_artificial: "0",
          invalid_number_artificial: "0",
          code_number: "0",
          sign_up_ids: [],
          users: [],
        };
        this.$store.dispatch("ADD_CAR", car);

        this.$router.replace({
          name: "scan",
          params: {
            car_number: this.carNumber,
            registration_time: now,
          },
        });
      } else {
        Toast.fail("请输入车牌号码");
      }
    },
    submit(value) {
      if (!this.checkFormat(value.plateNum)) {
        Toast.fail("请输入正确的车牌号码");
        return;
      }
      console.log(value);
      this.carNumber = value.plateNum;
      this.showInputCar = false;
    },
  },
};
</script>
